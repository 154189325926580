import { useNavigate } from "react-router";
import Headroom from "react-headroom";
import { useState } from "react";

export default function Navbar() {
  const navigate = useNavigate();
  const [isDark, setIsDark] = useState(false);

  return (
    <Headroom>
      <header>
        <nav
          className={`w-full px-5 md:px-9 py-3 md:py-5 flex bg-transparent text-xl top-0 ${
            isDark ? "text-[#444444]" : "text-[#1d1d1d]"
          } `}
        >
          <div className="w-1/3 flex">
            <p className="py-1 px-2 rounded-full hover:bg-[#ffffff36]">
              <i class="ri-mic-line"></i>
            </p>
          </div>
          <div className="w-1/3 text-center">
            <h2
              className="tracking-widest font-bold text-3xl md:text-4xl font-serif cursor-pointer"
              onClick={() => navigate("/")}
            >
              moooi
            </h2>
          </div>
          <div className="w-1/3 flex justify-end gap-1 md:gap-4">
            <p className="py-1 px-2 rounded-full hover:bg-[#ffffff36]">
              <i class="ri-search-line"></i>
            </p>
            <p className="py-1 px-2 rounded-full hover:bg-[#ffffff36]">
              <i class="ri-dashboard-fill"></i>
            </p>
            <p className="py-1 px-2 rounded-full hover:bg-[#ffffff36]">
              <i class="ri-store-line"></i>
            </p>
          </div>
        </nav>
      </header>
    </Headroom>
  );
}
