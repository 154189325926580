import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import MenuButton from "../components/MenuButton";
import { useNavigate } from "react-router";
import Footer from "../components/Footer";

const DUMMY_PRODUCT = [
  {
    id: 105,
    name: "Flock of Light",
    type: "Lighting",
    price: 749.0,
    detail:
      "Flock of Light, by Studio Toer, transforms from a light and airy artistic design piece at daytime to a swarm of bright warm lights at night. A swarm captured in stillness. Flock of Light comes in thre…",
    inStock: true,
    img: "https://www.moooi.com/_next/image?url=https%3A%2F%2Fcdn.moooi.com%2Ftmp%2Fimage-thumbnails%2FCollection%2FFlock%20of%20Light%2FFlock%20of%20Light%2011%2F40083%2Fimage-thumb__40083__header_fullscreen_2x_jpg%2FFlock-of-Light-11-V2.webp&w=3840&q=80",
    bgImg:
      "https://www.moooi.com/_next/image?url=https%3A%2F%2Fcdn.moooi.com%2Ftmp%2Fimage-thumbnails%2FCollection%2FFlock%20of%20Light%2FFlock%20of%20Light%2031%2F39728%2Fimage-thumb__39728__header_fullscreen_2x_jpg%2FFlock-of-Light-31-on-black-background.webp&w=3840&q=80",
  },
  {
    id: 106,
    name: "Hubble Bubble",
    type: "Lighting",
    price: 1885.0,
    detail:
      "Hubble Bubble, designed by Marcel Wanders, is an airy and joyful suspension lamp. Available in two sizes, with either 7 or 11 glass LED bubbles in oil-iridescent or frosted versions. Hubble Bubbl…",
    inStock: true,
    img: "https://www.moooi.com/_next/image?url=https%3A%2F%2Fcdn.moooi.com%2Fassets%2FCollection%2FHubble%2520Bubble%2FHubble%2520Bubble%25207%2Fhubble-bubble-7-frosted-small-on.png&w=1200&q=80",
    bgImg:
      "https://www.moooi.com/_next/image?url=https%3A%2F%2Fcdn.moooi.com%2Ftmp%2Fimage-thumbnails%2FCollection%2FHubble%20Bubble%2F27277%2Fimage-thumb__27277__header_fullscreen_2x_jpg%2FHubble-Bubble-both-sizes-on-grey-background-Hero.webp&w=3840&q=80",
  },
  {
    id: 101,
    name: "Dodo Pavone Bed Pillows",
    type: "Bedding & Bath",
    price: 90.0,
    detail:
      "The Dodo Pavone bed pillows are part of Moooi’s Extinct Animals bedding collection. These exclusive and extravagant bed pillows have a digital print inspired by the characteristics of the now extinct …",
    inStock: true,
    img: "https://www.moooi.com/_next/image?url=https%3A%2F%2Fcdn.moooi.com%2Ftmp%2Fimage-thumbnails%2FCollection%2FBedding%2FBed%20Pillows%2FDodo%20Pavone%20Bed%20Pillows%2F52998%2Fimage-thumb__52998__header_fullscreen_2x_jpg%2FDodo-Pavone_Green_US_UV_King-2x36x20-10_Topshot-front.webp&w=1080&q=80",
    bgImg:
      "https://www.moooi.com/_next/image?url=https%3A%2F%2Fcdn.moooi.com%2Ftmp%2Fimage-thumbnails%2FCollection%2FBedding%2FDuvets%2FDuvets-Mood-NL%2F52933%2Fimage-thumb__52933__header_fullscreen_2x_jpg%2FBeddinghouse_Moooi-Dodo-Pavone_green_versie2_TC.webp&w=1920&q=80",
  },
  {
    id: 102,
    name: "Dodo Pavone Duvet Cover Set",
    type: "Bedding & Bath",
    price: 230.0,
    detail:
      "The Dodo Pavone duvet cover set is part of the Extinct Animals bedding collection. This exclusive and extravagant duvet cover set has a digital print inspired by the characteristics of the now ex…",
    inStock: true,
    img: "https://www.moooi.com/_next/image?url=https%3A%2F%2Fcdn.moooi.com%2Ftmp%2Fimage-thumbnails%2FCollection%2FBedding%2FDuvets%2FDuvets-colored-sheets%2Fimage-thumb__56638__header_fullscreen_2x_jpg%2FDodo-Pavone-Green_Topshot.webp&w=1080&q=80",
    bgImg:
      "https://www.moooi.com/_next/image?url=https%3A%2F%2Fcdn.moooi.com%2Ftmp%2Fimage-thumbnails%2FCollection%2FBedding%2FDuvets%2FDuvets-Mood-NL%2F52933%2Fimage-thumb__52933__header_fullscreen_2x_jpg%2FBeddinghouse_Moooi-Dodo-Pavone_green_versie2_TC.webp&w=1920&q=80",
  },
  {
    id: 103,
    name: "Big George Double Seater",
    type: "Furniture",
    price: 4450.0,
    detail:
      "The Big George by Cristián Mohaded radiates a warm charm that extends beyond the practical utility of a seater. The expansive dimensions and gentle curves create an irresistible haven for relaxation, …",
    inStock: true,
    img: "https://www.moooi.com/_next/image?url=https%3A%2F%2Fcdn.moooi.com%2Ftmp%2Fimage-thumbnails%2FCollection%2FBig%20George%2FBig%20George%20Sofa%2FPhotography%2FProduct%20Images%2FPNG%2F91307%2Fimage-thumb__91307__header_fullscreen_2x_jpg%2FBig_George_Double_Seater_Arda_201_Product-02.webp&w=3840&q=80",
    bgImg:
      "https://www.moooi.com/_next/image?url=https%3A%2F%2Fcdn.moooi.com%2Ftmp%2Fimage-thumbnails%2FCollection%2FWallcovering%2FGreenhouse%20Gathering%2FJPG%2F90772%2Fimage-thumb__90772__header_fullscreen_2x_jpg%2FGreenhouseGathering_Arch_ForrestGreen.webp&w=3840&q=80",
  },
  {
    id: 104,
    name: "Big George Armchair",
    type: "Furniture",
    price: 3953.0,
    detail:
      "The Big George by Cristián Mohaded radiates a warm charm that extends beyond the practical utility of a seater. The expansive dimensions and gentle curves create an irresistible haven for relaxation, …",
    inStock: true,
    img: "https://www.moooi.com/_next/image?url=https%3A%2F%2Fcdn.moooi.com%2Ftmp%2Fimage-thumbnails%2FCollection%2FBig%20George%2FBig%20George%20Armchair%2FPhotography%2FProduct%20Images%2FPNG%2F85611%2Fimage-thumb__85611__header_fullscreen_2x_jpg%2FBig_George_Armchair_Spectrum_Agave_Product-01.webp&w=3840&q=80",
    bgImg:
      "https://www.moooi.com/_next/image?url=https%3A%2F%2Fcdn.moooi.com%2Ftmp%2Fimage-thumbnails%2FCollection%2FBig%20George%2F89923%2Fimage-thumb__89923__header_fullscreen_2x_jpg%2F01_Big_George_Living_02.webp&w=3840&q=80",
  },

  {
    id: 107,
    name: "Delft Blue Vases Black Edition",
    type: "Home Accessories",
    price: 400.0,
    detail:
      "The Moooi x Royal Delft Black Edition, specially crafted for four selected items from Marcel Wanders' Dutch Design collection: The Killing of the Piggy Bank, The Rabbit, Vase Model 6, and Vase Model 8…",
    inStock: true,
    img: "https://www.moooi.com/_next/image?url=https%3A%2F%2Fcdn.moooi.com%2Ftmp%2Fimage-thumbnails%2FCollection%2FDelft%20Blue%20Black%20Vases%2FProduct%20white%20and%20no%20background%2F90816%2Fimage-thumb__90816__header_fullscreen_2x_jpg%2FDelft-Blue-6.webp&w=1080&q=80",
    bgImg:
      "https://www.moooi.com/_next/image?url=https%3A%2F%2Fcdn.moooi.com%2Ftmp%2Fimage-thumbnails%2FCollection%2FDelft%20Blue%20Black%20Vases%2FProduct%20Images%2F90443%2Fimage-thumb__90443__header_fullscreen_2x_jpg%2FCombination_horizontal_01.webp&w=3840&q=80",
  },
  {
    id: 108,
    name: "Lucky O's Blanket",
    type: "Home Accessories",
    price: 189.0,
    detail:
      "Crafted from heavyweight 100% pure new wool using jacquard weaving, this blanket evokes the undulating rhythm of ocean waves. Its distinctive Seigaiha pattern, an ancient symbol representing luck, str…",
    inStock: true,
    img: "https://www.moooi.com/_next/image?url=https%3A%2F%2Fcdn.moooi.com%2Ftmp%2Fimage-thumbnails%2FCollection%2FBlankets%2FLucky%20O%2FProduct%20Shot%20Folded%2F92526%2Fimage-thumb__92526__header_fullscreen_2x_jpg%2FLucky%20O%27s%20Blanket%20Amber.webp&w=1080&q=80",
    bgImg:
      "https://www.moooi.com/_next/image?url=https%3A%2F%2Fcdn.moooi.com%2Ftmp%2Fimage-thumbnails%2FCollection%2FBlankets%2FLucky%20O%2FHero%2F92516%2Fimage-thumb__92516__header_fullscreen_2x_jpg%2FLucky%20O%27s%20Blankets_Hero.webp&w=3840&q=80",
  },
  {
    id: 109,
    name: "Shape Rugs Carpet",
    type: "Wall & Floor",
    price: 2576.0,
    detail:
      "The Shape Rugs collection, consisting of the Big Scale designs, is a tapestry of creativity by Nikodem Szpunar, merging painting and design. These carpets transcend their traditional role, becoming dy…",
    inStock: true,
    img: "https://www.moooi.com/_next/image?url=https%3A%2F%2Fcdn.moooi.com%2Ftmp%2Fimage-thumbnails%2FCollection%2FCarpets%2FShape%20rugs%2FProduct%20images%2F90759%2Fimage-thumb__90759__header_fullscreen_2x_jpg%2F_0002_Szpunar-Shape-Rug-3---Blue_01.webp&w=1200&q=80",
    bgImg:
      "https://www.moooi.com/_next/image?url=https%3A%2F%2Fcdn.moooi.com%2Ftmp%2Fimage-thumbnails%2FCollection%2FCarpets%2FShape%20rugs%2FApplied%2F91012%2Fimage-thumb__91012__header_fullscreen_2x_jpg%2FNikodem_Szpunar-horizontal.webp&w=2048&q=80",
  },
  {
    id: 110,
    name: "Crystal Carpet",
    type: "Wall & Floor",
    price: 2880.0,
    detail:
      "Looking at carpet Crystal by Ingimar Einarsson is like looking at crystals reflected by a thousand mirrors. A geometric kaleidoscope of translucent layers creates an ongoing depth. Inspired by a natur…",
    inStock: true,
    img: "https://www.moooi.com/_next/image?url=https%3A%2F%2Fcdn.moooi.com%2Ftmp%2Fimage-thumbnails%2FCollection%2FCarpets%2FDropshadow%20Transparant%20Background%2F14209%2Fimage-thumb__14209__header_fullscreen_2x_jpg%2FCrystal%20Brown-Design-190003.webp&w=1200&q=80",
    bgImg:
      "https://www.moooi.com/_next/image?url=https%3A%2F%2Fcdn.moooi.com%2Ftmp%2Fimage-thumbnails%2FCollection%2FCarpets%2FDropshadow%20Transparant%20Background%2F14209%2Fimage-thumb__14209__header_fullscreen_2x_jpg%2FCrystal%20Brown-Design-190003.webp&w=1080&q=80",
  },
  {
    id: 111,
    name: "In the Meadow Scarf",
    type: "Body & Beauty",
    price: 159.0,
    detail:
      "Elevate your daily wear with a piece of art. With whimsical and abstract shapes, the silk scarves of Emma Larsson’s In The Meadow collection depict mesmerising aquarelles. The bright and soft colours …",
    inStock: true,
    img: "https://www.moooi.com/_next/image?url=https%3A%2F%2Fcdn.moooi.com%2Ftmp%2Fimage-thumbnails%2FCollection%2FIn%20The%20Meadow%20Scarf%2F38299%2Fimage-thumb__38299__header_fullscreen_2x_jpg%2FIn-the-meadow-scarf-leaves.webp&w=1080&q=80",
    bgImg:
      "https://www.moooi.com/_next/image?url=https%3A%2F%2Fcdn.moooi.com%2Ftmp%2Fimage-thumbnails%2FCollection%2FIn%20The%20Meadow%20Scarf%2F38289%2Fimage-thumb__38289__header_fullscreen_2x_jpg%2FLeaves_Scarf_by_Emma_Larsson.webp&w=3840&q=80",
  },
];
export default function Product() {
  const [filteredCollection, setFilteredCollection] = useState(DUMMY_PRODUCT);
  const [collectionType, setCollectionType] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [select, setSelect] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!collectionType) {
      setFilteredCollection(DUMMY_PRODUCT);
    } else {
      const filterData = DUMMY_PRODUCT.filter(
        (value) => value.type === collectionType
      );
      setFilteredCollection(filterData);
    }
  }, [collectionType]);

  useEffect(() => {
    if (select) {
      const newProduct = DUMMY_PRODUCT.find((value) => value.name === select);
      console.log(newProduct);

      setSelectedProduct(newProduct);
    }
  }, [select]);

  useEffect(() => {
    if (selectedProduct) {
      navigate("/productdetail", { state: { selectedProduct } });
    }
  }, [selectedProduct, navigate]);

  return (
    <>
      <Navbar />
      <MenuButton />
      <section className="px-5 md:px-20">
        <div className="flex flex-col items-center">
          <h1 className="text-5xl md:text-7xl tracking-widers text-[#444444] italic">
            {collectionType ? collectionType : <p>Our Collection</p>}
          </h1>
          <p className="py-5 md:w-1/3 text-center font-thin text-gray-500">
            Our collection contains work from emerging talent and
            internationally recognized designers. Together, we create A Life
            Extraordinary.
          </p>
          <ul className="flex md:flex-row flex-col gap-4 py-5 overflow-hidden">
            <li
              onClick={() => setCollectionType("Bedding & Bath")}
              className="px-3 py-[2px] cursor-pointer border-[2px] border-gray-500 text-gray-900 rounded-full hover:bg-[#ececec] text-center"
            >
              Bedding & Bath
            </li>
            <li
              onClick={() => setCollectionType("Furniture")}
              className="px-3 py-[2px] cursor-pointer border-[2px] border-gray-500 text-gray-900 rounded-full hover:bg-[#ececec] text-center"
            >
              Furniture
            </li>
            <li
              onClick={() => setCollectionType("Lighting")}
              className="px-3 py-[2px] cursor-pointer border-[2px] border-gray-500 text-gray-900 rounded-full hover:bg-[#ececec] text-center"
            >
              Lighting
            </li>
            <li
              onClick={() => setCollectionType("Home Accessories")}
              className="px-3 py-[2px] cursor-pointer border-[2px] border-gray-500 text-gray-900 rounded-full hover:bg-[#ececec] text-center"
            >
              Home Accessories
            </li>
            <li
              onClick={() => setCollectionType("Wall & Floor")}
              className="px-3 py-[2px] cursor-pointer border-[2px] border-gray-500 text-gray-900 rounded-full hover:bg-[#ececec] text-center"
            >
              Wall & Floor
            </li>
            <li
              onClick={() => setCollectionType("Body & Beauty")}
              className="px-3 py-[2px] cursor-pointer border-[2px] border-gray-500 text-gray-900 rounded-full hover:bg-[#ececec] text-center"
            >
              Body & Beauty
            </li>
          </ul>

          <h2
            onClick={() => setCollectionType()}
            className="px-3 py-[2px] cursor-pointer border-[2px] border-black text-white bg-black rounded-full text-center"
          >
            Show All The Collection
          </h2>
        </div>
        <div className="grid grid-cols-3 gap-1">
          {filteredCollection.map((product) => (
            <div
              key={product.id}
              className="p-1 md:p-3 flex flex-col items-center  cursor-pointer gap-1"
              onClick={() => {
                setSelect(product.name);
                // navigate("/productdetail");
              }}
            >
              <div className="h-[300px] md:h-[550px] flex justify-center items-center md:p-2 ">
                <img src={product.img} alt="product" />
              </div>

              <h3 className="font-medium text-sm md:text-base text-center">
                {product.name}
              </h3>
              <p className="text-sm">€ {product.price}</p>
              {product.inStock && (
                <p className="text-xs italic text-gray-500">
                  <span className="text-green-500">• </span>InStock
                </p>
              )}
            </div>
          ))}
        </div>
      </section>
      <Footer />
    </>
  );
}
