import React from "react";
import Navbar from "../components/Navbar";
import MenuButton from "../components/MenuButton";
import Footer from "../components/Footer";

export default function Contact() {
  return (
    <>
      <Navbar />
      <section className="w-full h-screen flex flex-col justify-center items-center gap-5 -mt-16 md:-mt-20">
        <h1 className="text-3xl lg:text-7xl tracking-widest">Contact</h1>
        <h3 className="text-xl md:w-1/4 w-4/5 text-center">
          Do you want to know more regarding Moooi or our products? We’d be
          happy to hear from you!
        </h3>
        <button className="py-2 px-3 shadow-xl rounded-full">
          <span>
            <i class="ri-message-line"></i>
          </span>{" "}
          Send us an e-mail
        </button>
      </section>
      <section className="hidden lg:flex flex-col px-10 pb-10">
        <h1 className="text-center text-8xl">Moooi Stores & Brand Store</h1>
        <div className="grid grid-cols-3 gap-4 py-10">
          <div className="">
            <img
              src="https://www.moooi.com/_next/image?url=https%3A%2F%2Fa.storyblok.com%2Ff%2F246251%2F1754x1476%2F4486f2a3ec%2Fmoooi-utrechtsestraat-facade.png&w=1920&q=80"
              alt="shop"
              className="w-full h-[600px] object-cover"
            />
            <h3 className="my-1">
              <span>
                <i class="ri-map-pin-2-fill"></i>
              </span>{" "}
              MOOOI STORE AMSTERDAM
            </h3>
          </div>
          <div className="">
            <img
              src="https://www.moooi.com/_next/image?url=https%3A%2F%2Fa.storyblok.com%2Ff%2F246251%2F1080x1350%2Fed2a86b32d%2Fgolden-chair-container.webp&w=1080&q=80"
              alt="shop"
              className="w-full h-[600px] object-cover"
            />
            <h3 className="my-1">
              <span>
                <i class="ri-map-pin-2-fill"></i>
              </span>{" "}
              NEWYORK BRAND STORE
            </h3>
          </div>
          <div className="">
            <img
              src="https://www.moooi.com/_next/image?url=https%3A%2F%2Fcdn.moooi.com%2Fassets%2FApplied%2FBrand-stores%20Showrooms%2FLA%20Showroom%2FMoooi_LA_Showroom_HollywooodSign.png&w=1920&q=80"
              alt="shop"
              className="w-full h-[600px] object-cover"
            />
            <h3 className="my-1">
              <span>
                <i class="ri-map-pin-2-fill"></i>
              </span>{" "}
              MOOOI SHOWROOM LOS ANGELOS
            </h3>
          </div>
        </div>
      </section>
      <MenuButton />
      <Footer />
    </>
  );
}
