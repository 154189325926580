import video from "../assets/Homepage.mp4";

import imgBath from "../assets/towels.webp";
import imgFurniture from "../assets/big_george_armchair_spectrum_agave_product_0.webp";
import imgTubelight from "../assets/tubelight.webp";
import imgHomeassessories from "../assets/home_accessories.webp";
import imgBody from "../assets/body_-_beauty.webp";
import imgRug from "../assets/shape_rugs_blue.webp";
import Navbar from "../components/Navbar";
import MenuButton from "../components/MenuButton";
import Footer from "../components/Footer";

export default function Home() {
  return (
    <>
      <Navbar />
      <main>
        {/* Main Home */}
        <section className="w-full h-screen flex justify-center items-center -mt-16 md:-mt-20">
          <video
            className="w-full h-full relative object-cover"
            src={video}
            loop
            muted
            autoPlay
          ></video>
          <div className="absolute lg:w-1/2 flex flex-col justify-center items-center text-white">
            <h3 className="text-sm text-[#ffffffc3] tracking-wider mb-2">
              PRODUCT STORY
            </h3>
            <h1 className="text-3xl lg:text-7xl text-center">
              Bend the rules with Tubelight
            </h1>
            <button className="text-base mt-8 px-4 py-2 bg-white text-gray-900 rounded-full duration-150 ease-in hover:mt-9 ">
              Discover our brand-new Tubelight
            </button>
          </div>
        </section>
        {/* Shop Collection */}
        <section>
          <div className="py-14 lg:px-16 px-5">
            <h3 className="lg:text-center text-2xl mb-4">
              SHOP OUR COLLECTION
            </h3>
            <div className="flex gap-2  overflow-x-auto text-sm ">
              <div className="p-4 rounded-xl hover:bg-[#F8F6F1] ease-in ">
                <img src={imgBath} alt="Bath" className="h-[170px] w-[170px]" />
                <h3 className="text-center w-[170px]">Bedding & Bath</h3>
              </div>
              <div className="p-4 rounded-xl hover:bg-[#F8F6F1] ease-in">
                <img
                  src={imgFurniture}
                  alt="Furniture"
                  className="h-[170px] w-[170px]"
                />
                <h3 className="text-center w-[170px]">Furniture</h3>
              </div>
              <div className="p-4 rounded-xl hover:bg-[#F8F6F1] ease-in">
                <img
                  src={imgTubelight}
                  alt="Tubelight"
                  className="h-[170px] w-[170px]"
                />
                <h3 className="text-center w-[170px]">Lighting</h3>
              </div>
              <div className="p-4 rounded-xl hover:bg-[#F8F6F1] ease-in">
                <img
                  src={imgHomeassessories}
                  alt="Tubelight"
                  className="h-[170px] w-[170px]"
                />
                <h3 className="text-center w-[170px]">Home Accessories</h3>
              </div>
              <div className="p-4 rounded-xl hover:bg-[#F8F6F1] ease-in">
                <img
                  src={imgRug}
                  alt="Tubelight"
                  className="h-[170px] w-[170px]"
                />
                <h3 className="text-center w-[170px]">Wall & Floor</h3>
              </div>
              <div className="p-4 rounded-xl hover:bg-[#F8F6F1] ease-in">
                <img
                  src={imgBody}
                  alt="Tubelight"
                  className="h-[170px] w-[170px]"
                />
                <h3 className="text-center w-[170px]">Body & Beauty</h3>
              </div>
            </div>
          </div>
        </section>
      </main>
      <MenuButton />
      <Footer />
    </>
  );
}
