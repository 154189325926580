import React, { useState } from "react";
import Modal from "./Modal";

export default function MenuButton({ setDark, setLight }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 ">
        <button
          onClick={() => setOpen(!open)}
          className="w-[80px] h-[80px] bg-[url('https://www.moooi.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fbutton.13ab0204.png&w=256&q=80')] bg-cover text-white hover:shadow-md hover:shadow-white rounded-full"
        >
          {!open ? <p>MENU</p> : <p>CLOSE</p>}
        </button>
      </div>
      <Modal open={open} onClose={() => setOpen(false)}></Modal>
    </>
  );
}
