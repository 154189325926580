import React from "react";
import { useNavigate } from "react-router";

export default function Modal({ open, onClose, children }) {
  const navigate = useNavigate();
  return (
    <>
      {open && (
        <div
          onClick={onClose}
          className="fixed  inset-0  flex justify-center items-center transition-colors visible bg-black/20"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="md:w-[300px] w-[250px] p-5 rounded-lg bg-gradient-to-tr from-neutral-700 to-neutral-900 text-white text-lg"
          >
            {children}
            <div className="flex gap-2">
              <i class="ri-search-line"></i>
              <input
                type="text"
                className="text-white bg-transparent border-none w-full"
                placeholder="Search for products, store"
              />
              <i class="ri-mic-line"></i>
            </div>
            <ul className="items-center flex flex-col gap-1 mt-5 text-2xl md:text-3xl">
              <li
                className="hover:list-disc cursor-pointer duration-300 ease-in-out"
                onClick={() => navigate("/product")}
              >
                Products
              </li>
              <li className="hover:list-disc cursor-pointer duration-300 ease-in-out">
                Stories
              </li>
              <li className="hover:list-disc cursor-pointer duration-300 ease-in-out">
                Gift Guide
              </li>
            </ul>
            <ul className=" items-center flex flex-col gap-2 mt-5 mb-5 text-xs md:text-sm uppercase tracking-widest ">
              <li
                className="hover:list-disc cursor-pointer duration-300 ease-in-out"
                onClick={() => navigate("/")}
              >
                Home
              </li>
              <li
                className="hover:list-disc cursor-pointer duration-300 ease-in-out"
                onClick={() => navigate("/about")}
              >
                About
              </li>
              <li className="hover:list-disc cursor-pointer duration-300 ease-in-out">
                Store Collector
              </li>
              <li className="hover:list-disc cursor-pointer duration-300 ease-in-out">
                Login
              </li>
              <li
                className="hover:list-disc cursor-pointer duration-300 ease-in-out"
                onClick={() => navigate("/contact")}
              >
                Contact
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
}
