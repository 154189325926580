import React from "react";
import Navbar from "../components/Navbar";
import MenuButton from "../components/MenuButton";
import { useLocation } from "react-router";
import Footer from "../components/Footer";

export default function ProductDetail() {
  const location = useLocation();

  const { selectedProduct } = location.state;
  return (
    <>
      <Navbar />
      <section className="w-full h-screen -mt-16 md:-mt-20">
        <img
          src={selectedProduct.bgImg}
          alt="background"
          className="object-cover object-center h-full w-full"
        />
      </section>
      <section className="py-5 md:py-7 px-7 md:px-10 text-[#444444]">
        <h1 className="text-5xl md:text-7xl font-light">
          {selectedProduct.name}
        </h1>
        <div className="md:flex my-5 justify-between">
          <div className="md:w-3/5">
            <h3 className="text-2xl md:text-4xl tracking-wide">
              {selectedProduct.detail}
            </h3>
            <button className="py-4 underline">Read More</button>
            <ul className="flex gap-3 overflow-x-auto py-5 px-3">
              <li className="px-3 py-2 rounded-full shadow-lg cursor-pointer ">
                ◎ View in AR
              </li>
              <li className="px-3 py-2 rounded-full shadow-lg ">
                ◎ Dimensions
              </li>
              <li className="px-3 py-2 rounded-full shadow-lg ">◎ Specs</li>
            </ul>
          </div>
          <div className="my-5">
            <h3 className="my-2 text-lg">
              <span className="text-3xl text-gray-600 mr-2">
                <i class="ri-shopping-cart-fill"></i>
              </span>
              Worldwide shipping on Extra O Collection
            </h3>
            <h3 className="my-2 text-lg">
              <span className="text-3xl text-gray-600 mr-2">
                <i class="ri-verified-badge-line"></i>
              </span>
              5 Year Warranty after registration
            </h3>
          </div>
        </div>
      </section>
      <MenuButton />
      <Footer />
    </>
  );
}
