import React from "react";

export default function Footer() {
  return (
    <footer className="text-white bg-black py-12 md:px-10 px-5 flex flex-col">
      <div className="">
        <button className="px-3 py-1 rounded-full border-2 border-gray-600">
          My Country/region is not listed <i class="ri-arrow-down-s-line"></i>
        </button>
      </div>
      <div className="md:flex py-10">
        <ul className="flex flex-col gap-3 md:w-1/4 text-xl">
          <li>Store Locator </li>
          <li>Material </li>
          <li>Library Shipping & Returns </li>
          <li>FAQ</li>
        </ul>
        <ul className="flex flex-col gap-3 md:w-1/4 text-xl">
          <li>Newsletter</li>
          <li>Press Area </li>
          <li>Jobs </li>
        </ul>
      </div>
      <div className="md:flex justify-between">
        <ul className="w-full md:w-1/2 flex gap-4 text-3xl justify-between md:justify-start py-5 md:py-0">
          <li>
            <i class="ri-pinterest-fill"></i>
          </li>
          <li>
            <i class="ri-instagram-line"></i>
          </li>
          <li>
            <i class="ri-vimeo-line"></i>
          </li>
          <li>
            <i class="ri-linkedin-box-fill"></i>
          </li>
          <li>
            <i class="ri-facebook-box-fill"></i>
          </li>
          <li>
            <i class="ri-spotify-fill"></i>
          </li>
        </ul>
        <ul className="md:flex gap-2">
          <li>Disclaimer</li>
          <li>Cookie Policy</li>
          <li>General Conditions</li>
          <li>Privacy Statement</li>
          <li>©2024 Moooi</li>
        </ul>
      </div>
    </footer>
  );
}
